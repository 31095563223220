import ApiConfig from '../config/apiConfig';
export default {
  // 手机号显示****
  setPhoneNumSecret(cardNum) {
    if (cardNum == "" || !cardNum) return "未输入";
    let strArr = cardNum.split("");
    let strLength = strArr.length;
    if (strArr.length <= 3) return cardNum;
    else if (strArr.length <= 7)
      return "***" + cardNum.substr(strLength - 4);
    else
      return (
        cardNum.substr(0, 3) + "*****" + cardNum.substr(strLength - 4)
      );
  },
  getImgSrc(str) {
    let Expression = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
    let objExp = new RegExp(Expression);
    // let random = Math.random().toString()
    let randomStr = `?v=xuexijingling`;

    if (objExp.test(str) == true) {
      return str + randomStr;
    } else if (!str) {
      return require('../assets/image/errorImage.jpg')
    } else {
      // console.log(ApiConfig.cdnUrl + str + randomStr)
      return ApiConfig.cdnUrl + str + randomStr;
    }
  },
  formatSeconds(value) {
    var secondTime = parseInt(value);
    var minuteTime = 0;
    var hourTime = 0;
    if (secondTime > 60) {

      minuteTime = parseInt(secondTime / 60);

      secondTime = parseInt(secondTime % 60);

      if (minuteTime > 60) {

        hourTime = parseInt(minuteTime / 60);

        minuteTime = parseInt(minuteTime % 60);
      }
    }
    var result = "" + parseInt(secondTime) + "秒";

    if (minuteTime > 0) {
      result = "" + parseInt(minuteTime) + "分" + result;
    }
    if (hourTime > 0) {
      result = "" + parseInt(hourTime) + "小时" + result;
    }
    return result;
  },

  getArea(id) {
    let arr = JSON.parse(localStorage.getItem('provices'))
    if (!id) return '未知'
    if (arr && arr.length) {
      let item = arr.find(e => e.id == id)
      return item.name
    } else {
      return '未知'
    }
  },
  getAllHref(url) {
    let Expression = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?/;
    let objExp = new RegExp(Expression);

    if (objExp.test(url) == true) {
      return url;
    } else if (!url) {
      return ''
    } else {
      // console.log(ApiConfig.cdnUrl + str + randomStr)
      return 'https://' + url;
    }

  },
  getSchool(id) {
    let arr = JSON.parse(localStorage.getItem('schools'))
    if (!id) return '未知'
    if (arr && arr.length) {
      let item = arr.find(e => e.id == id)
      return item.name
    } else {
      return '未知'
    }
  },


}
