<template>
  <div id="app" :class="grey?'grayscale':''">
    <router-view />
  </div>
</template>

<script>
import store from './store';
import { mapState } from "vuex";
export default {
  name: 'App',
  computed: {
    ...mapState(['grey']),
  },
  created() {

    let userInfo = window.sessionStorage.getItem('userInfo')
    if (userInfo) {
      store.commit('login', { userInfo: JSON.parse(userInfo) });
    }

  },
  methods: {

  }
}

</script>

<style>
body {
  margin: 0;
  padding: 0;
  background: #f5f7fa;
}
.grayscale {
-webkit-filter: grayscale(100%) !important;
-moz-filter: grayscale(100%)!important;
-ms-filter: grayscale(100%)!important;
-o-filter: grayscale(100%)!important;
filter: grayscale(100%)!important;
filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1) !important;
}
.el-breadcrumb {
  font-size: 14px !important;
  margin: 20px 0;
}

#nprogress .bar {
  background: #fc8116 !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px #fc8116, 0 0 5px #fc8116 !important;
}

#nprogress .spinner-icon {
  border-top-color: #fc8116 !important;
  border-left-color: #fc8116 !important;
}
</style>
