/**
 * @file Axios的Vue插件（添加全局请求/响应拦截器）
 */
import axios from 'axios'
import router from '../router'
import apiconfig from '../config/apiConfig'
import { Message } from 'element-ui';
import NProgress from "nprogress";
import "nprogress/nprogress.css";
NProgress.configure({ showSpinner: false });


// 获得操作系统版本

const http = axios.create({
  baseURL: apiconfig.baseUrl,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
  timeout: 30000
})

// 拦截request,设置全局请求为ajax请求
http.interceptors.request.use((config) => {
  NProgress.start();
  let token = window.sessionStorage.getItem("token");
  if (token) {
    config.headers.Authorization = window.sessionStorage.getItem("token");
  }
  return config

})
http.interceptors.response.use(response => {
  NProgress.done();
  if (response.data.msg == 'token失效') {
    Message.error('登录状态失效，请从新登录')
    router.push('/login')
  } else if (response.data.code !== 0) {
    if (!response.data.code) {
      if (response.status !== 200) {
        Message.error(response.statusText)
      }
    } else {
      Message.error(response.data.msg)
    }
  }
  return response;
}, error => {
  console.log(error);
  if (error.message.includes('timeout')) { // 判断请求异常信息中是否含有超时timeout字符串
    Message.error('网络超时');
  } else {
    Message.error('网络错误');
  }

})

export default http
